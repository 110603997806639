module.exports = {
  'campuslife.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'campuslife.browser.title': 'Campus life - Devine Kask Conservatory',
  'campuslife.study.title': 'Study Devine at KASK campus Kortrijk Buda',
  'campuslife.study.p':
    'The school brings together talented and motivated students with outstanding educators who in their turn invite active artists, designers and theorists in art, design & technology from all over the world to exchange thoughts and co-work. Our school is known for its innovative and interdisciplinary approach to education and research, and its close collaboration with industry and the social & cultural sector. ',
  'campuslife.study.p2':
    'Devine is part of the school of arts KASK, campus Kortrijk, an institution with a human dimension. The classes are consciously made up of a small amount of students, which ensures a personalised follow-up, one-on-one coaching and a “familial” atmosphere for the students. ',
  'campuslife.comm.subtitle': 'International community',
  'campuslife.comm.p1':
    'There are over 500 international students currently studying in Kortrijk so you will fit right in with this vibrant international community. We regularly organize events and workshops and there is a central meeting point on campus where international student can hang out. For housing options offered by our school you can contact Stuvo as soon as your application has been approved.',
  'campuslife.comm.p2':
    'One week before the regular classes start, the international students are invited to the orientation week so you can get familiar with the campus and take care of practical arrangements. That way you are off to a flying start. Every student gets their own ‘Belgian Study Buddy’ who will introduce you to the student life at our campus and make you feel right at home.',
  'campuslife.comm.p4':
    'In search of extra information on living costs, medical facilities, academic grading system... be sure to check out the <studentguide>Prospective Student Guide.</studentguide> ',
  'campuslife.troeven.title': 'Why study in Flanders',
  'campuslife.troeven.p1':
    'Education in Belgium ranks third-best among the 35 OECD countries, well above the EU and world averages. Australia and Canada tied at the top of the list, followed by the Belgium and the Netherlands. ',
  'campuslife.troeven.p2':
    'Flemish cities have a rich cultural heritage, and you will be studying and living in the pittoresk Bruge region. ',
  'campuslife.troeven.p3':
    'History aside, don’t forget to enjoy the vibrant night life.',
  'campuslife.troeven.p4':
    'The National Belgian train rail system (NMBS) is very student friendly, with frequent direct lines to every major Belgian city. Additionally, Paris is only two hours away.',
  'campuslife.troeven.p5':
    'Planning on traveling further? Belgium is in the heart of Europe and a perfect place to depart from with easy access to other European countries.',
  'campuslife.troeven.p6':
    'Our gastronomic reputation is well known around the world: Belgian chocolate, beer, French fries (which are actually Belgian), waffles - Students can eat their lunch at the campus restaurant <budakitchen>Buda Kitchen</budakitchen> at very student friendly prices. ',
  'campuslife.troeven.p7':
    'The Belgian standard of living ranks among one of the best in the world, and every citizen has access to up-to-date medical care.',
  'campuslife.troeven.p8':
    'Flemish people are known for speaking several foreign languages. Most of the people whom you will meet speak English quite well, and are happy to switch to English to help you out. ',
  'campuslife.troeven.p9':
    'You will also find many people who speak or understand French, German, Spanish, Italian, Russian and many other languages.',
  'campuslife.creative.title': 'Kortrijk, a world city of design',
  'campuslife.creative.subtitle': 'Unesco Creative City of Design',
  'campuslife.creative.p1':
    "Kortrijk has a long-standing tradition of international trade. As early as the Middle Ages, Kortrijk's flax was one of the most important Flemish export goods. Thanks to the flax trade the city acquired a reputation of craftsmanship, quality design and entrepreneurship. However, wars and economic setbacks required the textile industry to reinvent itself on various occasions. This necessary transformation turned the region of Kortrijk into what it is today: creative and resilient. That is why Kortrijk was awarded the membership of the <unesco>UNESCO Creative Cities Network</unesco>.",
  'campuslife.creative.subtitle2': 'Creative Hub on Buda island',
  'campuslife.creative.p2':
    'Our campus is located on a small island in the centre of the city. It is always buzzing with creative energy. The most diverse organizations in culture, education, healthcare and the economy are in full development, as are many vibrant student and citizen initiatives.',
  'campuslife.creative.p3':
    'At the centre is <broelkaai>Broelkaai 6</broelkaai>, the new creative hotspot and meeting point. As a creative coworking space, this site is the gateway to Buda Island and a showcase for creativity in Kortrijk. Artists, designers, students, creative entrepreneurs, active citizens and numerous cultural organizations will work together. And you too can come and work, relax, eat, drink, discover and enjoy. Via this bustling meeting place you can further explore the entire island: from the Budascoop with its arthouse cinema to the Buda factory. From industrial flax museum Texture to the sunbathing lawn and skate bowl on Buda Beach.',
  'campuslife.facility.subtitle': 'Other communal facilities ',
  'campuslife.facility.p1': 'Olympic swimming pool',
  'campuslife.facility.p2': 'Depart event hall of the future',
  'campuslife.facility.p3': 'Start-up hub Hangar K',
  'campuslife.facility.p4': 'Makerspace: Budalab',
}

// extracted by mini-css-extract-plugin
export var colorNotBlack = "#161615";
export var colorNotWhite = "#f8f8f8";
export var content = "SocialMedia-module--content--b7753 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c";
export var extendedState = "SocialMedia-module--extendedState--bf359";
export var icon = "SocialMedia-module--icon--ec391";
export var iconBeh = "SocialMedia-module--iconBeh--f332d SocialMedia-module--icon--ec391";
export var iconEmail = "SocialMedia-module--iconEmail--43e9a SocialMedia-module--icon--ec391";
export var iconFb = "SocialMedia-module--iconFb--f9776 SocialMedia-module--icon--ec391";
export var iconGit = "SocialMedia-module--iconGit--34813 SocialMedia-module--icon--ec391";
export var iconInsta = "SocialMedia-module--iconInsta--2242f SocialMedia-module--icon--ec391";
export var iconLinked = "SocialMedia-module--iconLinked--de9ca SocialMedia-module--icon--ec391";
export var iconYou = "SocialMedia-module--iconYou--9d797 SocialMedia-module--icon--ec391";
export var invertedSocials = "SocialMedia-module--invertedSocials--62c3a SocialMedia-module--socials--e3e83";
export var s1 = "calc(.94rem*1.5)";
export var s2 = "calc(calc(.94rem*1.5)*1.5)";
export var s_1 = "calc(.94rem/1.5)";
export var socialItem = "SocialMedia-module--socialItem--5e4c6";
export var socials = "SocialMedia-module--socials--e3e83";
export var tokens = "\"../styles/tokens.css\"";